var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cadastro-base',{attrs:{"salvar":_vm.salvar,"cancelar":_vm.cancelar}},[_c('template',{slot:"conteudo"},[_c('div',{attrs:{"slot":"modal-title"},slot:"modal-title"},[_c('h5',[_vm._v("Datas de atualizações das rotinas")]),_c('fieldset',{staticClass:"font-weight-bold text-muted"},[_vm._v(" Nos informe quais dias do mês deseja que o sistema faça a atualização das rotinas automáticas. ")]),_c('hr',{staticClass:"mx-0 mt-0 mb-2",staticStyle:{"border-top":"1px solid #a3a4a66b !important"}})]),_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("CERTIDÕES "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"InfoIcon","size":"12","title":_vm.mensagemPadraoCnd}})],1)])],1),_c('b-row',{staticStyle:{"margin-top":"1%"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-form-group',{attrs:{"label":"RFB/PGFN","label-for":"diaRfbPgfnInput"}},[_c('validation-provider',{attrs:{"rules":"diaValido","name":"RFB/PGFN"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"diaRfbPgfnInput","type":"number","placeholder":"Dia da consulta","disabled":_vm.form.cndRfbPgfn.isBloqueado},on:{"blur":function($event){return _vm.formatarNumero('diaRfbPgfn')}},model:{value:(_vm.form.cndRfbPgfn.dia),callback:function ($$v) {_vm.$set(_vm.form.cndRfbPgfn, "dia", $$v)},expression:"form.cndRfbPgfn.dia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.cndRfbPgfn.isBloqueado)?_c('fieldset',{staticClass:"text-danger",staticStyle:{"margin-top":"5px"}},[_vm._v("Bloqueado até "+_vm._s(this.form.cndRfbPgfn.dataBloqueio))]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-form-group',{attrs:{"label":"MTE","label-for":"diaMteInput"}},[_c('validation-provider',{attrs:{"rules":"diaValido","name":"MTE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"diaMteInput","type":"number","placeholder":"Dia da consulta","disabled":_vm.form.cndMte.isBloqueado},on:{"blur":function($event){return _vm.formatarNumero('diaMte')}},model:{value:(_vm.form.cndMte.dia),callback:function ($$v) {_vm.$set(_vm.form.cndMte, "dia", $$v)},expression:"form.cndMte.dia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.cndMte.isBloqueado)?_c('fieldset',{staticClass:"text-danger",staticStyle:{"margin-top":"5px"}},[_vm._v("Bloqueado até "+_vm._s(this.form.cndMte.dataBloqueio))]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-form-group',{attrs:{"label":"Trabalhista","label-for":"diaTrabalhistaInput"}},[_c('validation-provider',{attrs:{"rules":"diaValido","name":"Trabalhista"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"diaTrabalhistaInput","type":"number","placeholder":"Dia da consulta","disabled":_vm.form.cndTrabalhista.isBloqueado},on:{"blur":function($event){return _vm.formatarNumero('diaTrabalhista')}},model:{value:(_vm.form.cndTrabalhista.dia),callback:function ($$v) {_vm.$set(_vm.form.cndTrabalhista, "dia", $$v)},expression:"form.cndTrabalhista.dia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.cndTrabalhista.isBloqueado)?_c('fieldset',{staticClass:"text-danger",staticStyle:{"margin-top":"5px"}},[_vm._v("Bloqueado até "+_vm._s(this.form.cndTrabalhista.dataBloqueio))]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-form-group',{attrs:{"label":"Estadual","label-for":"diaEstadualInput"}},[_c('validation-provider',{attrs:{"rules":"diaValido","name":"Estadual"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"diaEstadualInput","type":"number","placeholder":"Dia da consulta","disabled":_vm.form.cndEstadual.isBloqueado},on:{"blur":function($event){return _vm.formatarNumero('diaEstadual')}},model:{value:(_vm.form.cndEstadual.dia),callback:function ($$v) {_vm.$set(_vm.form.cndEstadual, "dia", $$v)},expression:"form.cndEstadual.dia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.cndEstadual.isBloqueado)?_c('fieldset',{staticClass:"text-danger",staticStyle:{"margin-top":"5px"}},[_vm._v("Bloqueado até "+_vm._s(this.form.cndEstadual.dataBloqueio))]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-form-group',{attrs:{"label":"Municipal","label-for":"diaMunicipalInput"}},[_c('validation-provider',{attrs:{"rules":"diaValido","name":"Municipal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"diaMunicipalInput","type":"number","placeholder":"Dia da consulta","disabled":_vm.form.cndMunicipal.isBloqueado},on:{"blur":function($event){return _vm.formatarNumero('diaMunicipal')}},model:{value:(_vm.form.cndMunicipal.dia),callback:function ($$v) {_vm.$set(_vm.form.cndMunicipal, "dia", $$v)},expression:"form.cndMunicipal.dia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.cndMunicipal.isBloqueado)?_c('fieldset',{staticClass:"text-danger",staticStyle:{"margin-top":"5px"}},[_vm._v("Bloqueado até "+_vm._s(this.form.cndMunicipal.dataBloqueio))]):_vm._e()],1)],1)],1)],1),_c('fieldset',{staticClass:"font-weight-bold text-muted",staticStyle:{"margin-top":"10px"}},[_vm._v(" FGTS será atualizada conforme a data do seu vencimento. ")]),_c('div',{staticStyle:{"margin-top":"5px","margin-block-end":"50px"}},[_c('fieldset',{staticClass:"font-weight-bold text-muted"},[_vm._v(" Ao salvar o cadastro, essa tela ficará com o campo alterado bloqueado para alterações temporariamente. As parametrizações só podem ser refeitas após 30 dias! ")]),(_vm.form.cndRfbPgfn.isBloqueado || _vm.form.cndMte.isBloqueado || _vm.form.cndTrabalhista.isBloqueado ||
        _vm.form.cndEstadual.isBloqueado || _vm.form.cndMunicipal.isBloqueado)?_c('fieldset',{staticClass:"font-weight-bold",staticStyle:{"color":"#ea5455"}},[_vm._v(" Os campos que se encontram bloqueados devem permanecer assim por 30 dias. Após esse período será permitido efetuar novas alterações. ")]):_vm._e()]),_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("FEDERAIS e-CAC "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"InfoIcon","size":"12","title":_vm.mensagemPadraoECac}})],1)])],1),_c('b-row',{staticStyle:{"margin-top":"1%"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-form-group',{attrs:{"label":"Situação Fiscal","label-for":"situacaoFiscalInput"}},[_c('validation-provider',{attrs:{"rules":"diaValido","name":"Situação Fiscal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"situacaoFiscalInput","type":"number","placeholder":"Dia da atualização","disabled":_vm.form.ecacSituacaoFiscal.isBloqueado},on:{"blur":function($event){return _vm.formatarNumero('diaSituacaoFiscal')}},model:{value:(_vm.form.ecacSituacaoFiscal.dia),callback:function ($$v) {_vm.$set(_vm.form.ecacSituacaoFiscal, "dia", $$v)},expression:"form.ecacSituacaoFiscal.dia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.ecacSituacaoFiscal.isBloqueado)?_c('fieldset',{staticClass:"text-danger",staticStyle:{"margin-top":"5px"}},[_vm._v("Bloqueado até "+_vm._s(this.form.ecacSituacaoFiscal.dataBloqueio))]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-form-group',{attrs:{"label":"DCTFWeb","label-for":"diaDctfwebInput"}},[_c('validation-provider',{attrs:{"rules":"diaValido","name":"DCTFWeb"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"diaDctfwebInput","type":"number","placeholder":"Dia da atualização","disabled":_vm.form.ecacDctfWeb.isBloqueado},on:{"blur":function($event){return _vm.formatarNumero('diaDctfweb')}},model:{value:(_vm.form.ecacDctfWeb.dia),callback:function ($$v) {_vm.$set(_vm.form.ecacDctfWeb, "dia", $$v)},expression:"form.ecacDctfWeb.dia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.ecacDctfWeb.isBloqueado)?_c('fieldset',{staticClass:"text-danger",staticStyle:{"margin-top":"5px"}},[_vm._v("Bloqueado até "+_vm._s(this.form.ecacDctfWeb.dataBloqueio))]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-form-group',{attrs:{"label":"DAS","label-for":"diaDasInput"}},[_c('validation-provider',{attrs:{"rules":"diaValido","name":"DAS"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"diaDasInput","type":"number","placeholder":"Dia da atualização","disabled":_vm.form.ecacDas.isBloqueado},on:{"blur":function($event){return _vm.formatarNumero('diaDas')}},model:{value:(_vm.form.ecacDas.dia),callback:function ($$v) {_vm.$set(_vm.form.ecacDas, "dia", $$v)},expression:"form.ecacDas.dia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.ecacDas.isBloqueado)?_c('fieldset',{staticClass:"text-danger",staticStyle:{"margin-top":"5px"}},[_vm._v("Bloqueado até "+_vm._s(this.form.ecacDas.dataBloqueio))]):_vm._e()],1)],1)],1)],1),_c('fieldset',{staticClass:"font-weight-bold text-muted",staticStyle:{"margin-top":"10px"}},[_vm._v(" O sistema efetuará a atualização automática uma vez ao mês, na data definida acima. ")]),_c('div',{staticStyle:{"margin-top":"5px","margin-block-end":"50px"}},[_c('fieldset',{staticClass:"font-weight-bold text-muted"},[_vm._v(" Após o sistema efetuar a atualização da rotina o campo ficará bloqueado temporariamente para alterações até o dia 01º do mês seguinte. A DCTFWeb e o DAS são atualizados apenas para as empresas que possuem suas configurações marcadas no cadastro da empresa! ")])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }